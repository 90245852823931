import React from 'react'
import classNames from 'classnames'
import parse from 'html-react-parser'
import RemoveMd from 'remove-markdown'
import { Link } from 'gatsby'

const ns = `event-card`

const EventCard = ({ eventcard }) => {
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  const title = parse(eventcard.title)

  return (
    <div className={`${rootClassnames} col-md-6 col-lg-4`}>
      <p className="event--date">{eventcard.date}</p>
      <Link to={eventcard.link}>
        <div>
          <h2 className="event--title">{title}</h2>
        </div>
      </Link>
      <div className={`${ns}__feature-image`}>
        <Link to={eventcard.link}>
          <img src={eventcard.url} alt="" />
        </Link>
      </div>
      <p>{RemoveMd(eventcard.note)}</p>
    </div>
  )
}

export default EventCard

EventCard.defaultProps = {
  eventcard: {
    date: 'FRIDAY, AUGUST 12, 7PM',
    title: 'PURPLE GARDEN CINEMA: ATLANTICS',
    url: '/calendar/Image16@2x.png',
    note: 'Directed by Mati Diop',
    link: '#',
  },
}
