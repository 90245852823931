import React from 'react'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import Layout from '../components/Layout/Layout'
import Hero from '../components/Hero/Hero'
import EventCard from '../components/EventCard/EventCard'

const ns = `calendar-page`

const Calendar = ({ data }) => {
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  return (
    <Layout>
      <Hero
        hero={{
          urlMobile: data.content.frontmatter.hero.hero_image_mobile.publicURL,
          urlDesktop:
            data.content.frontmatter.hero.hero_image_desktop.publicURL,
        }}
      />
      <div className={rootClassnames}>
        <div className={`${ns}__container`}>
          <div className="row">
            <div className={`${ns}__title col-12`}>
              <h1>{data.content.frontmatter.title}</h1>
            </div>
          </div>
          <div className="row">
            {data.allevents.nodes.map(event => (
              <EventCard
                eventcard={{
                  date: event.frontmatter.event_date,
                  title: event.frontmatter.title,
                  url: event.frontmatter.featuredimage.publicURL,
                  note: event.frontmatter.sub_title,
                  link: event.fields.slug,
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Calendar

export const calendarPageQuery = graphql`
  query CalendarPageQuery($id: String!) {
    content: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        hero {
          hero_image_desktop {
            publicURL
          }
          hero_image_mobile {
            publicURL
          }
        }
      }
    }
    allevents: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "event-post" } } }
      sort: { fields: frontmatter___event_date_time }
    ) {
      nodes {
        frontmatter {
          title
          sub_title
          event_date
          event_date_time
          featuredimage {
            publicURL
          }
        }
        fields {
          slug
        }
      }
    }
  }
`
